import React from "react";

type ReactChildren = { children: React.ReactNode };

const Dialog = ({ children }: ReactChildren) => (
  <>
    <div className="bg-black opacity-50 fixed top-0 left-0 z-10 w-full h-full" />
    <div className="fixed top-0 left-0 z-20 w-full h-full flex justify-center items-center">
      <div className="relative bg-white flex flex-col items-center w-full h-full md:max-w-3xl md:h-[400px]">
        { children }
      </div>
    </div>
  </>
);

const DialogHeader = ({ children }: ReactChildren) => (
  <h2 className="text-2xl tracking-[0.5rem] lowercase border-b-[1px] border-b-slate-700 mt-2">
    { children }
  </h2>
);
Dialog.Header = DialogHeader;

const DialogBody = ({ children }: ReactChildren) => (
  <div className="grow w-full overflow-scroll">
    { children }
  </div>
);
Dialog.Body = DialogBody;

const DialogFooter = ({ children }: ReactChildren) => (
  <div className="w-full flex justify-end p-5 space-x-5">
    { children }
  </div>
);
Dialog.Footer = DialogFooter;

export default Dialog;