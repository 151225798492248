import { useState } from "react";

const useErrorState = () => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  return {
    error, setError,
    message, setMessage,
  };
};

export default useErrorState;