import { useContext, useEffect, useState } from "react";
import { AppContext, SocketContext } from "../state";

import Dialog from "./Dialog";
import StoreEntry from "./StoreEntry";
import { ToastStatus } from "./Toast";

import { SocketResult, storeSort } from "../lib";

const ADD_STORE = "~ADD~STORE~";
const STORE_TEMPLATE = {
  _id: ADD_STORE,
  name: "",
  order: 0,
};

const EditStoresDialog = ({ onClose }: { onClose: () => void }) => {
  const { errorState, appState, toastState } = useContext(AppContext);
  const socket = useContext(SocketContext);

  const [editStores, setEditStores] = useState([...appState.stores]);
  const [editID, setEditID] = useState<string | null>(null);
  const [removeID, setRemoveID] = useState<string | null>(null);
  const [newStore, setNewStore] = useState({...STORE_TEMPLATE});
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (editID) document.getElementById("edit-field")?.focus();
  }, [editID]);

  useEffect(() => {
    if (!(editID || removeID)) setEditStores([...appState.stores]);
    if (appState.stores.length === 0) {
      setNewStore({...STORE_TEMPLATE});
      setEditID(ADD_STORE);
    }
  }, [editID, removeID, appState.stores]);

  const addedStore = (data: SocketResult) => {
    switch (data.result) {
      case "err":
        errorState.setMessage(data.message!);
        errorState.setError(true);
        break;

      case "exists":
        toastState.setMessage(`${newStore.name} has already been added`);
        toastState.setStatus(ToastStatus.Warning);
        toastState.setActive(true);
        break;

      case "ok":
        const allStores = [data.store!, ...appState.stores];
        allStores.sort(storeSort);
        appState.setStores(allStores);
        toastState.setMessage(`${data.store!.name} has been added`);
        toastState.setStatus(ToastStatus.Success);
        toastState.setActive(true);
        break;
  
      case "add":
      case "invalid store":
      case "not found":
      case "update":
      default:
        errorState.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        errorState.setError(true);
        break;
    }

    setEditID(null);
    setSending(false);
  };

  const removedStore = (data: SocketResult) => {
    switch (data.result) {
      case "err":
        errorState.setMessage(data.message!);
        errorState.setError(true);
        break;

      case "not found":
        appState.setItems(appState.items.filter(item => item.store !== removeID));
        appState.setStores(appState.stores.filter(store => store._id !== removeID));
        toastState.setMessage("The removed store could not be found on the server");
        toastState.setStatus(ToastStatus.Warning);
        toastState.setActive(true);
        break;

      case "ok":
        appState.setItems(appState.items.filter(item => item.store !== removeID));
        appState.setStores(appState.stores.filter(store => store._id !== removeID));
        toastState.setMessage("The store has been removed");
        toastState.setStatus(ToastStatus.Success);
        toastState.setActive(true);
        break;

      case "add":
      case "exists":
      case "invalid store":
      case "update":
      default:
        errorState.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        errorState.setError(true);
        break;
    }

    setRemoveID(null);
    setSending(false);
  };

  const updatedStore = (data: SocketResult) => {
    switch (data.result) {
      case "err":
        errorState.setMessage(data.message!);
        errorState.setError(true);
        break;

      case "exists":
        const store = editStores.filter(s => s._id === editID)[0];
        toastState.setMessage(`${store.name} has already been added`);
        toastState.setStatus(ToastStatus.Warning);
        toastState.setActive(true);
        break;

      case "not found":
        appState.setItems(appState.items.filter(i => i.store !== editID));
        appState.setStores(appState.stores.filter(s => s._id !== editID));
        toastState.setMessage("The store record could not be found on the server");
        toastState.setStatus(ToastStatus.Warning);
        toastState.setActive(true);
        break;

      case "ok":
        const allStores = [...editStores.filter(s => s._id === editID), ...appState.stores.filter(s => s._id !== editID)];
        allStores.sort(storeSort);
        appState.setStores(allStores);
        toastState.setMessage("The store has been updated");
        toastState.setStatus(ToastStatus.Success);
        toastState.setActive(true);
        break;

      case "add":
      case "invalid store":
      case "update":
      default:
        errorState.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        errorState.setError(true);
        break;
    }

    setEditID(null);
    setSending(false);
  };

  return (
    <Dialog>
      <Dialog.Header>Edit<span className="font-bold">Stores</span></Dialog.Header>
      <Dialog.Body>
        { editID === ADD_STORE && (
          <StoreEntry
            store={newStore}
            disabled={sending}
            edit
            noRemove={editStores.length === 0}
            onCancel={() => setEditID(null)}
            onChange={(event) => setNewStore({ ...newStore, name: event.target.value }) }
            onSave={() => {
              setSending(true);
              socket.emit("add store", newStore, addedStore);
            }}
          />
        )}
        { editStores.map(store => (
          <StoreEntry
            key={store._id}
            store={store}
            disabled={!!(sending || (editID && editID !== store._id) || (removeID && removeID !== store._id))}
            edit={!!(editID && editID === store._id)}
            noRemove={editStores.length < 2}
            remove={!!(removeID && removeID === store._id)}
            onCancel={() => {
              setEditID(null);
              setRemoveID(null);
            }}
            onChange={(event) => setEditStores(editStores.map(s => s._id === editID ? { ...s, name: event.target.value } : s))}
            onEdit={() => setEditID(store._id)}
            onRemove={() => {
              setSending(true);
              socket.emit("remove store", store._id, removedStore);
            }}
            onRemoveRequest={() => setRemoveID(store._id)}
            onSave={() => {
              setSending(true);
              socket.emit("update store", editStores.filter(s => s._id === store._id)[0], updatedStore);
            }}
          />
        ))}
      </Dialog.Body>
      <Dialog.Footer>
        <button
          disabled={!!(editID || removeID || sending)}
          className={`${!!(editID || removeID || sending) ? "text-slate-300" : "text-blue-700"} font-bold uppercase`}
          onClick={() => {
            setNewStore({...STORE_TEMPLATE});
            setEditID(ADD_STORE);
          }}
        >
          Add Store
        </button>
        <button
          disabled={!!(editID || removeID || sending)}
          className={`${!!(editID || removeID || sending) ? "text-slate-300" : ""} font-bold uppercase`}
          onClick={onClose}
        >
          Close
        </button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default EditStoresDialog;