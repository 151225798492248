export interface Item {
  _id: string,
  name: string,
  order: number,
  quantity: number,
  store: string,
  strike: boolean,
};

export type SocketResultString = "add" | "err" | "exists" | "invalid store" | "not found" | "ok" | "update";

export interface SocketResult {
  result: SocketResultString,
  item?: Item,
  items?: Item[],
  store?: Store,
  stores?: Store[],
  message?: string,
};

export interface Store {
  _id: string,
  name: string,
  order: number,
};

export const itemSort = (a: Item, b: Item) => {
  if (a.strike === b.strike) {
    if (a.order === b.order) {
      if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1;
      if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1;
      return 0;
    }

    return a.order - b.order;
  }

  return a.strike ? 1 : -1;
};

export const storeSort = (a: Store, b: Store) => {
  if (a.order === b.order) {
    if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1;
    if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1;
    return 0;
  }

  return a.order - b.order;
};