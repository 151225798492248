import { useContext, useEffect } from "react";

import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Accordion from "./components/Accordion";
import EditItemDialog from "./components/EditItemDialog";
import EditStoresDialog from "./components/EditStoresDialog";
import ErrorMessage from "./components/ErrorMessage";
import ItemEntry from "./components/ItemEntry";
import ItemInput from "./components/ItemInput";
import Toast, { ToastStatus } from "./components/Toast";

import { Item, itemSort, SocketResult, Store, storeSort } from "./lib";
import useApplicationState, { AppContext, SocketContext } from "./state";

function App() {
  const state = useApplicationState();
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.on("add item", (item: Item) => {
      const items = [item, ...state.App.items];
      items.sort(itemSort);
      state.App.setItems(items);
    });

    socket.on("add store", (store: Store) => {
      const stores = [store, ...state.App.stores];
      stores.sort(storeSort);
      state.App.setStores(stores);
    });

    socket.on("items", (data: SocketResult) => {
      if (data.result === "ok") {
        data.items!.sort(itemSort);
        state.App.setItems(data.items!);
        state.Control.setReceivedItems(true);
      } else {
        state.Error.setMessage(data.message!);
        state.Error.setError(true);
      }
    });

    socket.on("remove item", (id: string) => {
      state.App.setItems(state.App.items.filter(i => i._id !== id));
    });

    socket.on("remove store", (id: string) => {
      state.App.setItems(state.App.items.filter(i => i.store !== id));
      state.App.setStores(state.App.stores.filter(s => s._id !== id));
    });

    socket.on("stores", (data: SocketResult) => {
      if (data.result === "ok") {
        state.App.setStores(data.stores!);
        state.Control.setReceivedStores(true);
      } else {
        state.Error.setMessage(data.message!)
        state.Error.setError(true);
      }
    });

    socket.on("update item", (item: Item) => {
      const items = state.App.items.map(i => i._id === item._id ? item : i);
      items.sort(itemSort);
      state.App.setItems(items);
    });

    socket.on("update store", (store: Store) => {
      const stores = state.App.stores.map(s => s._id === store._id ? store : s);
      stores.sort(storeSort);
      state.App.setStores(stores);
    });

    return () => {
      socket.off("add item");
      socket.off("add store");
      socket.off("items");
      socket.off("remove item");
      socket.off("remove store");
      socket.off("stores");
      socket.off("update item");
      socket.off("update store");
    };
  });

  useEffect(() => {
    state.Control.setReady(state.Control.receivedItems && state.Control.receivedStores);
    if (state.Control.ready && state.App.stores.length === 0)
      state.Control.setEditingStores(true);
  }, [state.App, state.Control]);

  useEffect(() => {
    if (state.Control.refocus) {
      const el = document.querySelector("#item-input") as HTMLInputElement;
      el?.focus();
      state.Control.setRefocus(false);
    }
  }, [state.Control]);

  const addedItem = (data: SocketResult) => {
    let items: Item[];

    switch (data.result) {
      case "add":
        items = [data.item!, ...state.App.items];
        items.sort(itemSort);
        state.App.setItems(items);
        state.Toast.setMessage(`${data.item!.name} added to the list`);
        state.Toast.setStatus(ToastStatus.Success);
        state.Toast.setActive(true);
        break;

      case "err":
        state.Error.setMessage(data.message!);
        state.Error.setError(true);
        break;

      case "invalid store":
        state.Toast.setMessage("The selected store could not be found on the server");
        state.Toast.setStatus(ToastStatus.Error);
        state.Toast.setActive(true);
        break;

      case "update":
        items = [data.item!, ...state.App.items.filter(i => i._id !== data.item!._id)];
        items.sort(itemSort);
        state.App.setItems(items);
        state.Toast.setMessage(`${data.item!.name} is already on the list, and its quantity has been increased`);
        state.Toast.setStatus(ToastStatus.Info);
        state.Toast.setActive(true);
        break;

      case "exists":
      case "not found":
      case "ok":
      default:
        state.Error.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        state.Error.setError(true);
        break;
    }

    state.App.setNewItem("");
    state.Control.setSending(false);
    state.Control.setRefocus(true);
  };

  const removedItem = (data: SocketResult) => {
    switch (data.result) {
      case "err":
        state.Error.setMessage(data.message!);
        state.Error.setError(true);
        break;

      case "not found":
        state.App.setItems(state.App.items.filter(i => i._id !== state.App.removeItem));
        state.Toast.setMessage("The selected item was not found on the server");
        state.Toast.setStatus(ToastStatus.Warning);
        state.Toast.setActive(true);
        break;

      case "ok":
        state.App.setItems(state.App.items.filter(i => i._id !== state.App.removeItem));
        state.Toast.setMessage("The item has been removed");
        state.Toast.setStatus(ToastStatus.Success);
        state.Toast.setActive(true);
        break;

      case "add":
      case "exists":
      case "invalid store":
      case "update":
      default:
        state.Error.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        state.Error.setError(true);
        break;
    }

    state.App.setRemoveItem(null);
    state.Control.setSending(false);
  };

  const strikeItem = (id: string) => (data: SocketResult) => {
    switch (data.result) {
      case "err":
        state.Error.setMessage(data.message!);
        state.Error.setError(true);
        break;

      case "invalid store":
        state.Toast.setMessage("The selected store was not found on the server");
        state.Toast.setStatus(ToastStatus.Error);
        state.Toast.setActive(true);
        break;

      case "not found":
        state.App.setItems(state.App.items.filter(i => i._id !== id));
        state.Toast.setMessage("The item being modified was not found on the server");
        state.Toast.setStatus(ToastStatus.Error);
        state.Toast.setActive(true);
        break;

      case "ok":
        const items = state.App.items.map(i => i._id === id ? data.item! : i);
        items.sort(itemSort);
        state.App.setItems(items);
        break;

      case "add":
      case "exists":
      case "update":
      default:
        state.Error.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        state.Error.setError(true);
        break;
    }

    state.Control.setSending(false);
  };

  const updatedItem = (data: SocketResult) => {
    switch (data.result) {
      case "err":
        state.Error.setMessage(data.message!);
        state.Error.setError(true);
        break;

      case "invalid store":
        state.Toast.setMessage("The selected store was not found on the server");
        state.Toast.setStatus(ToastStatus.Error);
        state.Toast.setActive(true);
        break;

      case "not found":
        state.App.setItems(state.App.items.filter(i => i._id !== state.App.editItem!._id));
        state.Toast.setMessage("The item being modified was not found on the server");
        state.Toast.setStatus(ToastStatus.Error);
        state.Toast.setActive(true);
        break;

      case "ok":
        const items = state.App.items.map(i => i._id === state.App.editItem!._id ? data.item! : i);
        items.sort(itemSort);
        state.App.setItems(items);
        state.Toast.setMessage("The item has been updated");
        state.Toast.setStatus(ToastStatus.Success);
        state.Toast.setActive(true);
        break;

      case "add":
      case "exists":
      case "update":
      default:
        state.Error.setMessage(`An unexpected result ("${data.result}") was returned from the server`);
        state.Error.setError(true);
        break;
    }

    state.Control.setEditingItem(false);
    state.Control.setSending(false);
    state.App.setEditItem(null);
  };

  return (
    <div className="h-screen flex flex-col items-center text-slate-700">
      <h1 className="text-3xl tracking-[0.5rem] lowercase my-3">
        Shopping<span className="font-bold">List</span>
      </h1>
      { state.Error.error ? (
        <ErrorMessage message={state.Error.message} />
      ) : !state.Control.ready ? (
        <FAI icon={faSpinner} size="5x" spin pulse className="mt-[80px]" />
      ) : (
        <div className="w-full md:max-w-3xl px-5">
          <Toast
            active={state.Toast.active}
            duration={state.Toast.duration}
            status={state.Toast.status}
            onDeactivate={() => state.Toast.setActive(false)}
          >
            { state.Toast.message }
          </Toast>
          { state.Control.editingStores && (
            <AppContext.Provider value={{ appState: state.App, errorState: state.Error, toastState: state.Toast}}>
              <EditStoresDialog onClose={() => state.Control.setEditingStores(false)}/>
            </AppContext.Provider>
          )}
          { state.Control.editingItem && (
            <EditItemDialog
              item={state.App.editItem!}
              stores={state.App.stores}
              onCancel={() => {
                state.Control.setEditingItem(false);
                state.App.setEditItem(null);
              }}
              onChange={(event) => {
                state.App.setEditItem({...state.App.editItem, [event.target.name]: EventTarget.name === "quantity" ? parseInt(event.target.value) : event.target.value} as Item);
              }}
              onSave={() => {
                state.Control.setSending(true);
                socket.emit("update item", state.App.editItem, updatedItem);
              }}
            />
          )}
          <button
            disabled={!!(state.Control.sending || state.App.removeItem)}
            className={`font-bold uppercase ${!!(state.Control.sending || state.App.removeItem) ? "text-slate-300" : ""}`}
            onClick={() => state.Control.setEditingStores(true)}
          >
            Edit Stores
          </button>
          <Accordion>
            { state.App.stores.length === 0 ? (
              <Accordion.Panel fixed>
                <Accordion.Header>
                  <h2 className="text-2xl font-bold">No Stores Added</h2>
                </Accordion.Header>
              </Accordion.Panel>
            ) : state.App.stores.map(store => {
              const count = state.App.items.filter(item => item.store === store._id).length;

              return (
                <Accordion.Panel key={store._id} panelID={store._id}>
                  <Accordion.Header disabled={!!(state.Control.sending || state.App.removeItem)}>
                    <p className="italic">{count} Item{count !== 1 && "s"}</p>
                    <div className="grow" />
                    <p className="text-2xl text-right font-bold">{store.name}</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ItemInput
                      disabled={state.Control.sending || state.Control.editingStores || state.Control.editingItem || !!state.App.removeItem}
                      sending={state.Control.sending && !state.App.removeItem}
                      value={state.App.newItem}
                      onChange={({target}) => state.App.setNewItem(target.value)}
                      onClick={() => {
                        state.Control.setSending(true);
                        socket.emit("add item", { store: store._id, name: state.App.newItem}, addedItem);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter" && state.App.newItem.trim().length > 0) {
                          state.Control.setSending(true);
                          socket.emit("add item", { store: store._id, name: state.App.newItem }, addedItem);
                        }
                      }}
                    />
                    <div className="w-full md:max-w-3xl flex flex-col items-center">
                      { state.App.items.filter(item => item.store === store._id).length === 0 ? (
                        <h2 className="text-2xl font-bold text-center py-5">No items on the list</h2>
                      ) : state.App.items.filter(item => item.store === store._id).map(item => (
                        <ItemEntry 
                          key={item._id}
                          item={item}
                          disabled={state.Control.sending || (!!state.App.removeItem && state.App.removeItem !== item._id)}
                          remove={!!state.App.removeItem && state.App.removeItem === item._id}
                          onCancel={() => state.App.setRemoveItem(null)}
                          onEdit={() => {
                            state.App.setEditItem({...item});
                            state.Control.setEditingItem(true);
                          }}
                          onRemove={() => {
                            state.Control.setSending(true);
                            socket.emit("remove item", item._id, removedItem);
                          }}
                          onRemoveRequest={() => state.App.setRemoveItem(item._id)}
                          onToggleStrike={() => {
                            state.Control.setSending(true);
                            socket.emit("update item", {...item, strike: !item.strike }, strikeItem(item._id));
                          }}
                        />
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Panel>
              );
            })}
          </Accordion>
        </div>
      )}
    </div>
  );
}

export default App;
