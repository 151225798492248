import React, { useEffect, useState } from "react";

export enum ToastStatus {
  Success,
  Info,
  Error,
  Warning,
};

interface ToastProps {
  active: boolean,
  children: React.ReactNode,
  duration: number,
  status: ToastStatus,
  onDeactivate: () => void,
};

const Toast: React.FC<ToastProps> = ({
  active,
  children,
  duration,
  status,
  onDeactivate
}) => {
  const [timeoutID, setTimeoutID] = useState<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (active && !timeoutID && duration > 0) {
      setTimeoutID(setTimeout(() => {
        setTimeoutID(null);
        onDeactivate();
      }, duration));
    }
  }, [active, duration, timeoutID, onDeactivate]);

  let toastColors;
  switch (status) {
    case ToastStatus.Success:
      toastColors = "bg-green-200 border-green-800 text-green-800 font-bold";
      break;

    case ToastStatus.Info:
      toastColors = "bg-blue-200 border-blue-800 text-blue-800 font-bold";
      break;

    case ToastStatus.Error:
      toastColors = "bg-red-200 border-red-800 text-red-800 font-bold";
      break;

    case ToastStatus.Warning:
      toastColors = "bg-yellow-200 border-yellow-800 text-yellow-800 font-bold";
      break;
  }

  return (
    <div className="fixed top-1 left-0 z-50 w-full h-full flex flex-col items-center pointer-events-none">
      { active && 
        <div
          className={`${toastColors} w-10/12 md:max-w-xl border-2 rounded px-4 py-3 pointer-events-auto`}
          onClick={() => {
            clearTimeout(timeoutID!);
            setTimeoutID(null);
            onDeactivate();
          }}
        >
          { children }
        </div>
      }
    </div>
  );
};

export default Toast;