import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const ErrorMessage = ({ message }: { message: string }) => (
  <div className="flex flex-col w-full items-center mt-20">
    <FAI icon={faCircleExclamation } size="6x" className="text-red-700 mb-3" />
    <h3 className="text-2xl font-bold mb-5">Error!</h3>
    <p>{ message }</p>
  </div>
);

export default ErrorMessage;