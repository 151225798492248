import { useState } from "react";

const useControlState = () => {
  const [editingItem, setEditingItem] = useState(false);
  const [editingStores, setEditingStores] = useState(false);
  const [ready, setReady] = useState(false);
  const [receivedItems, setReceivedItems] = useState(false);
  const [receivedStores, setReceivedStores] = useState(false);
  const [refocus, setRefocus] = useState(false);
  const [sending, setSending] = useState(false);

  return {
    editingItem, setEditingItem,
    editingStores, setEditingStores,
    ready, setReady,
    receivedItems, setReceivedItems,
    receivedStores, setReceivedStores,
    refocus, setRefocus,
    sending, setSending,
  };
};

export default useControlState;