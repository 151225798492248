import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faPencil, faSpinner, faX} from "@fortawesome/free-solid-svg-icons";

import { Item } from "../lib";

interface ItemEntryProps {
  disabled: boolean,
  item: Item,
  remove: boolean,
  onCancel: () => void,
  onEdit: () => void,
  onRemove: () => void,
  onRemoveRequest: () => void,
  onToggleStrike: () => void,
};

const ItemEntry = ({
  disabled,
  item,
  remove,
  onCancel,
  onEdit,
  onRemove,
  onRemoveRequest,
  onToggleStrike,
}: ItemEntryProps) => (
  <div className="w-full flex space-x-3 items-center py-4 border-b-[1px] border-b-slate-300 last:border-b-0">
    { remove ? (
      <>
        <button
          disabled={disabled}
          onClick={onRemove}
          className={disabled ? "text-slate-300" : "bg-red-700 text-white text-sm py-1 px-2 rounded uppercase"}
        >
          {disabled ? <FAI icon={faSpinner} size="lg" fixedWidth spin pulse /> : "Remove"}
        </button>
        <div className={`w-full ${disabled ? "text-slate-300" : ""}`}>
          <p>{item.name}</p>
          <p className="text-sm">Qty: {item.quantity}</p>
        </div>
        <button
          disabled={disabled}
          onClick={onCancel}
        >
          <FAI icon={faX} size="lg" fixedWidth className={disabled ? "text-slate-300" : "text-blue-700"} />
        </button>
      </>
    ) : (
      <>
        <button
          disabled={disabled}
          onClick={onRemoveRequest}
        >
          <FAI icon={faCircleMinus} size="lg" fixedWidth className={disabled ? "text-slate-300" : "text-red-700"} />
        </button>
        <div className={`w-full ${disabled ? "text-slate-300" : ""}`} onClick={disabled ? () => {} : onToggleStrike}>
          <p className={item.strike ? "line-through text-slate-300"  : ""}>{item.name}</p>
          <p className={`text-sm ${item.strike ? "line-through text-slate-300" : ""}`}>Qty: {item.quantity}</p>
        </div>
        <button
          disabled={disabled}
          onClick={onEdit}
        >
          <FAI icon={faPencil} size="lg" fixedWidth className={disabled ? "text-slate-300" : ""} />
        </button>
      </>
    )}
  </div>
);

export default ItemEntry;