import { useState } from "react";
import { Item, Store } from "../lib";

const useAppState = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [stores, setStores] = useState<Store[]>([]);

  const [newItem, setNewItem] = useState("");
  const [editItem, setEditItem] = useState<Item | null>(null);
  const [removeItem, setRemoveItem] = useState<string | null>(null);

  return {
    items, setItems,
    stores, setStores,
    newItem, setNewItem,
    editItem, setEditItem,
    removeItem, setRemoveItem,
  };
};

export default useAppState;