import { createContext } from "react";
import { io } from "socket.io-client";

import useAppState from "./app";
import useControlState from "./control";
import useErrorState from "./error";
import useToastState from "./toast";

const useApplicationState = () => {
  return {
    App: useAppState(),
    Control: useControlState(),
    Error: useErrorState(),
    Toast: useToastState(),
  };
};

export default useApplicationState;

export interface AppContextData {
  appState: ReturnType<typeof useAppState>,
  errorState: ReturnType<typeof useErrorState>,
  toastState: ReturnType<typeof useToastState>,
};
export const AppContext = createContext<AppContextData>({} as AppContextData);

export const socket = io();
export const SocketContext = createContext(socket);
