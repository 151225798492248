import { ChangeEventHandler, KeyboardEventHandler } from "react";
import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faSpinner, faSquarePlus } from "@fortawesome/free-solid-svg-icons";

interface ItemInputProps {
  disabled: boolean,
  sending: boolean,
  value: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  onClick: () => void,
  onKeyPress: KeyboardEventHandler<HTMLInputElement>,
};

const ItemInput =({
  disabled,
  sending,
  value,
  onChange,
  onClick,
  onKeyPress,
}: ItemInputProps) => (
  <div className="w-full md:max-w-3xl mt-5 flex space-x-3 items-center">
    <input
      type="text"
      id="item-input"
      disabled={disabled}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder="Enter a new item"
      className={`w-full border-b-[1px] border-b-slate-300 outline-none p-2 pt-0 focus:border-b-slate-700 ${disabled ? "text-slate-300" : ""}`}
    />
    <button
      disabled={disabled || value.length === 0}
      onClick={onClick}
      className={disabled || value.length === 0 ? "text-slate-300" : "text-green-700"}
    >
      <FAI
        icon={sending ? faSpinner : faSquarePlus}
        size="lg"
        fixedWidth
        spin={sending}
        pulse={sending}
      />
    </button>
  </div>
);

export default ItemInput;