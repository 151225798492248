import { useState } from "react";

import { ToastStatus } from "../components/Toast";

const useToastState = () => {
  const [active, setActive] = useState(false);
  const [duration, setDuration] = useState(3000);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<ToastStatus>(ToastStatus.Success);

  return {
    active, setActive,
    duration, setDuration,
    message, setMessage,
    status, setStatus,
  };
};

export default useToastState;