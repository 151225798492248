import React, { createContext, useContext, useState } from "react";

import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface AccordionContextData {
  activePanel: string | null,
  onPanelSelect: (id: string) => void,
};
const AccordionContext = createContext({} as AccordionContextData);

interface HeaderProps {
  disabled?: boolean,
  children: React.ReactNode,
};

interface PanelContextData {
  fixed?: boolean,
  panelID?: string,
};
const PanelContext = createContext({} as PanelContextData);

interface PanelProps {
  fixed?: boolean,
  panelID?: string,
  children: React.ReactNode,
}

interface ReactChildren { children: React.ReactNode };

const Accordion = ({ children }: ReactChildren) => {
  const [activePanel, setActivePanel] = useState<string | null>(null);
  const onPanelSelect = (id: string) => setActivePanel(id === activePanel ? null : id);

  return (
    <AccordionContext.Provider value={{ activePanel, onPanelSelect }}>
      { children }
    </AccordionContext.Provider>
  );
};

const AccordionPanel = ({ fixed, panelID, children }: PanelProps) => (
  <div className="border-t-[1px] border-t-slate-300 mt-3 pt-2">
    <PanelContext.Provider value={{ fixed, panelID }}>
      { children }
    </PanelContext.Provider>
  </div>
);
Accordion.Panel = AccordionPanel;

const AccordionHeader = ({ disabled, children }: HeaderProps) => {
  const { activePanel, onPanelSelect } = useContext(AccordionContext);
  const { fixed, panelID } = useContext(PanelContext);

  return (
    <div className="flex justify-center items-baseline space-x-3" onClick={fixed || disabled ? () => {} : () => onPanelSelect(panelID!)}>
      { children }
      { !fixed && <FAI icon={activePanel === panelID ? faChevronUp : faChevronDown } size="lg" fixedWidth />}
    </div>
  );
};
Accordion.Header = AccordionHeader;

const AccordionBody = ({ children }: ReactChildren) => {
  const { activePanel } = useContext(AccordionContext);
  const { fixed, panelID } = useContext(PanelContext);

  return fixed || activePanel === panelID ? (
    <div className="h-auto">{ children }</div>
  ) : (
    <></>
  );
};
Accordion.Body = AccordionBody;

export default Accordion;