import { ChangeEventHandler, useEffect } from "react";

import Dialog from "./Dialog";

import { Item, Store } from "../lib";

interface EditItemDialogProps {
  item: Item,
  stores: Store[],
  onCancel: () => void,
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>,
  onSave: () => void,
};

const EditItemDialog = ({
  item,
  stores,
  onCancel,
  onChange,
  onSave,
}: EditItemDialogProps) => {
  useEffect(() => document.getElementsByName("name")[0]?.focus(), []);

  return (
    <Dialog>
      <Dialog.Header>Edit<span className="font-bold">Item</span></Dialog.Header>
      <Dialog.Body>
        <div className="w-full flex space-x-3 items-baseline p-5">
          <p className="w-1/5 md:w-1/6 text-right">Item:</p>
          <input
            type="text"
            value={item.name}
            onChange={onChange}
            placeholder="Enter the item name"
            name="name"
            className="grow text-lg outline-none border-b-[1px] border-b-slate-300 focus:border-b-slate-700"
          />
        </div>
        <div className="w-full flex space-x-3 items-baseline p-5">
          <p className="w-1/5 md:w-1/6 text-right">Quantity:</p>
          <input 
            type="number"
            min="1"
            value={item.quantity}
            onChange={onChange}
            placeholder="Enter the quantity"
            name="quantity"
            className="text-lg outline-none border-b-[1px] border-b-slate-300 focus:border-b-slate-700"
          />
        </div>
        <div className="w-full flex space-x-3 items-baseline p-5">
          <p className="w-1/5 md:w-1/6 text-right">Store:</p>
          <select
            value={item.store}
            onChange={onChange}
            name="store"
            className="w-full text-lg p-2 pr-4 overflow-hidden bg-white border-b-[1px] border-b-slate-300 outline-none focus:border-b-slate-700"
          >
            { stores.map(store => (
              <option key={store._id} value={store._id}>{store.name}</option>
            ))}
          </select>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <button
          disabled={item.name.trim().length === 0 || item.quantity < 1}
          className={`font-bold uppercase ${item.name.trim().length === 0 || item.quantity < 1 ? "text-slate-300" : "text-blue-700"}`}
          onClick={onSave}
        >
          Save
        </button>
        <button
          className="font-bold uppercase text-red-700"
          onClick={onCancel}
        >
          Cancel
        </button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default EditItemDialog;