import { ChangeEventHandler } from "react";

import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleMinus, faPencil, faSpinner, faX } from "@fortawesome/free-solid-svg-icons";

import { Store } from "../lib";

interface StoreEntryProps {
  disabled: boolean,
  edit: boolean,
  noRemove: boolean,
  remove?: boolean,
  store: Store,
  onCancel: () => void,
  onChange: ChangeEventHandler<HTMLInputElement>,
  onEdit?: () => void,
  onRemove?: () => void,
  onRemoveRequest?: () => void,
  onSave: () => void,
};

const StoreEntry = ({
  disabled,
  edit,
  noRemove,
  remove,
  store,
  onCancel,
  onChange,
  onEdit,
  onRemove,
  onRemoveRequest,
  onSave,
}: StoreEntryProps) => (
  <div className="w-full flex space-x-4 items-center px-5 mt-5">
    { edit ? (
      <>
        <button
          disabled={disabled || noRemove}
          onClick={onCancel}
          className={disabled || noRemove ? "text-slate-300" : "text-red-700"}
        >
          <FAI icon={faX} size="lg" fixedWidth />
        </button>
        <input 
          type="text"
          value={store.name}
          onChange={onChange}
          onKeyPress={(event) => {
            if (event.key === "Enter" && store.name.trim().length > 0)
              onSave();
          }}
          placeholder="Enter store name"
          readOnly={disabled}
          id="edit-field"
          className={`w-full outline-none border-b-[1px] border-b-slate-300 focus:border-b-slate-700 text-xl ${disabled ? "text-slate-300" : ""}`}
        />
        <button
          disabled={disabled || store.name.trim().length === 0}
          onClick={onSave}
          className={disabled || store.name.trim().length === 0 ? "text-slate-300" : "text-blue-700"}
        >
          <FAI icon={disabled ? faSpinner : faCheck} size="lg" fixedWidth spin={disabled} pulse={disabled} />
        </button>
      </>
    ) : remove ? (
      <>
        <button
          disabled={disabled}
          onClick={onRemove}
          className={disabled ? "text-slate-300" : "bg-red-700 text-white text-sm py-1 px-2 rounded uppercase"}
        >
          { disabled ? <FAI icon={faSpinner} size="lg" fixedWidth spin pulse /> : "Remove" }
        </button>
        <input 
          type="text"
          value={store.name}
          readOnly
          className={`w-full outline-none border-b-[1px] border-b-slate-300 text-xl ${disabled ? "text-slate-300" : ""}`}
        />
        <button
          disabled={disabled}
          onClick={onCancel}
          className={disabled ? "text-slate-300" : "text-blue-700"}
        >
          <FAI icon={faX} size="lg" fixedWidth />
        </button>
      </>
    ) : (
      <>
        <button
          disabled={disabled || noRemove}
          onClick={onRemoveRequest}
          className={disabled || noRemove ? "text-slate-300" : "text-red-700"}
        >
          <FAI icon={faCircleMinus} size="lg" fixedWidth />
        </button>
        <input
          type="text"
          value={store.name}
          readOnly
          className={`w-full outline-none border-b-[1px] border-b-slate-300 text-xl ${disabled ? "text-slate-300" : ""}`}
        />
        <button
          disabled={disabled}
          onClick={onEdit}
          className={disabled ? "text-slate-300" : ""}
        >
          <FAI icon={faPencil} size="lg" fixedWidth />
        </button>
      </>
    )}
  </div>
);

export default StoreEntry;